<!-- eslint-disable vue/valid-v-on -->
<!-- eslint-disable max-lines -->
<template>
	<ValidationForm
		#default="{ handleSubmit, reset }"
		class="cassie-vertical-md"
	>
		<ViewLayout>
			<template #header-title>
				Create Data Subject
			</template>
			<template #header-caption>
				Create a new Data Subject to record preference data against
			</template>
			<template #content>
				<DataSubjectContactDetails
					:data-subject.sync="dataSubject"
					:edit-contact-details="false"
					:create-data-subject="true"
					:required-fields="requiredStanDakFields"
					hide-read-only-fields
				/>
				<v-card>
					<v-card-title>
						Preferences
					</v-card-title>
					<v-card-text>
						<div class="d-flex justify-space-between mb-4">
							<Dropdown
								v-if="showCsPortalBrandSelector"
								:value="selectedBrand"
								:label="brandFieldLabel"
								:items="csPortalBrandSelector"
								custom-sort
								class="d-inline-block align-self-start"
								@input="changeSelectedBrand"
							/>
							<div class="d-flex flex-row cassie-horizontal-sm">
								<Dropdown
									v-if="showResponseChannelForPrefSubmissions"
									v-model="selectedResponseChannelId"
									:items="responseChannelOptions"
									:label="responseChannelLabel"
									:rules="{ required: responseChannelForSubmissionsMandatory }"
								/>
								<Dropdown
									v-if="showSourceCodeForPrefSubmissions"
									v-model="selectedSourceCodeId"
									:items="sourceCodeOptions"
									:label="sourceCodeLabel"
									:rules="{ required: sourceCodeForSubmissionsMandatory }"
								/>
							</div>
						</div>

						<DataSubjectPreferenceExpansionPanels
							v-if="selectedBrand || selectedBrand === 0"
							ref="expansionPanels"
							:preference-groups="preferenceGroupsMapped"
							:is-creating="true"
							@cascade-selection="cascadeSelectionToSubGroup"
						>
							<template #header>
								<th
									class="text-left"
									style="width: 17%"
								>
									Consent / Preference
								</th>
								<th
									class="text-left"
									scope="col"
								>
									New Selection
								</th>
								<th
									v-if="canEditValidFromAndToFieldsForPrefSubmissions"
									class="text-left"
									scope="col"
									style="width: 20%"
								>
									Valid From
								</th>
								<th
									v-if="canEditValidFromAndToFieldsForPrefSubmissions"
									class="text-left"
									scope="col"
									style="width: 10%"
								>
									Valid To
								</th>
								<th
									class="text-left"
									scope="col"
									style="width: 15%"
								>
									Privacy Policy
								</th>
							</template>
							<template #rows="{ channels }">
								<template v-for="channel in channels">
									<tr
										v-if="channel.canBeAdded"
										:key="`1-${channel.channelId}`"
									>
										<td>
											{{ channel.channelName }}
										</td>
										<td>
											<v-radio-group
												:value="getValue(channel, 'preferenceValue')"
												row
												dense
												:error="invalidChannelSelection(channel)"
												hide-details
												class="ma-0 pa-0"
												@update:error="showError(channel,$event)"
												@change="setChannelOverride(channel, 'preferenceValue', $event, null, true)"
											>
												<v-radio
													v-for="{ value, text } in preferenceOptions"
													:key="value"
													:label="text"
													:value="value"
												/>
											</v-radio-group>
										</td>
										<td v-if="canEditValidFromAndToFieldsForPrefSubmissions">
											<v-row align="center">
												<v-col cols="8">
													<DatePicker
														:date="getValue(channel, 'validFrom')"
														:display-date="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE || getValue(channel, 'amendDateFrom') !== true ? `Valid immediately` : `${getValue(channel, 'validFrom')}`"
														:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE || getValue(channel, 'amendDateFrom') !== true"
														small
														@update:date="setChannelOverride(channel, 'validFrom', $event, null, true)"
													/>
												</v-col>
												<v-col cols="2">
													<v-checkbox
														:value="getValue(channel, 'amendDateFrom')"
														class="shrink m-0 p-0"
														label="Amend"
														:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
														@change="setChannelOverride(channel, 'amendDateFrom', $event, null, true)"
													/>
												</v-col>
											</v-row>
										</td>
										<td v-if="canEditValidFromAndToFieldsForPrefSubmissions">
											<DatePicker
												:date="getValue(channel, 'validTo')"
												:display-date="`${getValue(channel, 'validTo')}`"
												:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
												small
												@update:date="setChannelOverride(channel, 'validTo', $event, null, true)"
											/>
										</td>
										<td>
											<Dropdown
												:validate-on-show="true"
												:value="getValue(channel, 'privacyId')"
												:items="csPortalPrivacyPolicies(selectedBrand === 0 ? channel.brandId : selectedBrand)"
												:disabled="getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
												:rules="isPrivacyPolicyMandatory(channel)"
												custom-sort
												small
												class="py-2"
												label="Privacy policy"
												@input="setChannelOverride(channel, 'privacyId', $event, null, true)"
											/>
										</td>
									</tr>
									<tr
										v-if="channel.extendedPrefs && channel.extendedPrefs.length && channel.canBeAdded"
										:key="`2-${channel.channelId}`"
									>
										<td colspan="5">
											<v-row
												dense
												class="py-2"
											>
												<v-col
													v-for="extendedPref in channel.extendedPrefs"
													:key="extendedPref.keyName"
													cols="3"
												>
													<ExtendedPreferenceField
														:value="getValue(channel, 'extendedPrefs', extendedPref.keyId)"
														:label="extendedPref.keyLabel"
														:field-type-id="extendedPref.fieldTypeId"
														:available-values-if-dropdown="extendedPref.availableValuesIfDropdown"
														:disabled="!extendedPref.isEditable || getValue(channel, 'preferenceValue') === NO_CHANGE_VALUE"
														:rules="{ required: extendedPref.isMandatory && extendedPref.isEditable && getValue(channel, 'preferenceValue') !== NO_CHANGE_VALUE }"
														small
														@input="setChannelOverride(channel, 'extendedPrefs', $event, extendedPref.keyId, true)"
													/>
												</v-col>
											</v-row>
										</td>
									</tr>
								</template>
							</template>
						</DataSubjectPreferenceExpansionPanels>
					</v-card-text>
				</v-card>
			</template>
			<template #footer>
				<div class="d-flex">
					<v-spacer />
					<PrimaryActionButton
						:disabled="isInvalidSelection"
						@click="handleSubmit(() => createDataSubject(reset))"
					>
						<v-icon
							left
							dark
						>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</div>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import DataSubjectContactDetails from './data-subject-contact-details.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import DataSubjectPreferenceExpansionPanels from '../../../../../shared/components/data-subjects/data-subject-preference-expansion-panels.vue'
import { OPTED_IN, OPTED_OUT } from './data-subject/data-subject-constants.js'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import {
	brandFieldLabel,
	preferenceGroups,
	privacyPolicyMandatory,
	showResponseChannelForPrefSubmissions,
	responseChannels,
	responseChannelFieldLabel,
	responseChannelForSubmissionsMandatory,
	showSourceCodeForPrefSubmissions,
	sourceCodes,
	sourceCodeFieldLabel,
	sourceCodeForSubmissionsMandatory,
	canEditValidFromAndToFieldsForPrefSubmissions,
	channelRequiredStanDakFields,
	contactDetailsFields
} from '../../../../../shared/state/configuration.js'
import { postDataSubject } from '../../../../../shared/utils/api/data-subject.js'
import { DATE_FORMAT_DATE_PICKER } from '../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { csPortalPrivacyPolicies } from '../../../../../shared/state/privacy-policies.js'
import DatePicker from '../../../../../shared/components/date-picker.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import {
	csPortalBrandSelector,
	changeSelectedBrand,
	NO_BRAND_SELECTED,
	selectedBrand,
	showCsPortalBrandSelector
} from '../../../../../shared/state/brands.js'
import ExtendedPreferenceField from './extended-preference-field.vue'
import { EXTENDED_PREFERENCES_FIELD_TYPES } from '../../../../../shared/enums/extended-preferences.js'

const NO_CHANGE_VALUE = -1
const today = format(new Date(), DATE_FORMAT_DATE_PICKER)

export default {
	components: {
		ExtendedPreferenceField,
		ValidationForm,
		DatePicker,
		Dropdown,
		DataSubjectPreferenceExpansionPanels,
		PrimaryActionButton,
		DataSubjectContactDetails,
		ViewLayout
	},
	setup () {
		return {
			csPortalBrandSelector,
			selectedBrand,
			showCsPortalBrandSelector,
			brandFieldLabel,
			preferenceGroups,
			changeSelectedBrand,
			showSnackbar,
			csPortalPrivacyPolicies,
			EXTENDED_PREFERENCES_FIELD_TYPES,
			showResponseChannelForPrefSubmissions,
			responseChannels,
			responseChannelForSubmissionsMandatory,
			privacyPolicyMandatory,
			showSourceCodeForPrefSubmissions,
			sourceCodes,
			sourceCodeFieldLabel,
			sourceCodeForSubmissionsMandatory,
			canEditValidFromAndToFieldsForPrefSubmissions,
			channelRequiredStanDakFields,
			contactDetailsFields
		}
	},
	data () {
		return {
			dataSubject: {},
			preferences: {},
			NO_CHANGE_VALUE,
			selectedResponseChannelId: null,
			selectedSourceCodeId: null,
			requiredStanDakFields: []
		}
	},
	computed: {
		...mapGetters('auth', ['userHasUniversalBrand']),
		optedInChannels () {
			const optedInChannels = Object.keys(this.preferences).map(key => this.preferences[key])
				?.filter(x => x.preferenceValue === 1).map(({ channelId }) => channelId) || []
			return optedInChannels
		},

		isInvalidSelection () {
			const result = this.requiredStanDakFields.length > 0
			return result
		},
		responseChannelLabel () {
			return `${responseChannelFieldLabel.value}${this.responseChannelForSubmissionsMandatory ? ' *' : ''}`
		},
		sourceCodeLabel () {
			return `${sourceCodeFieldLabel.value}${this.sourceCodeForSubmissionsMandatory ? ' *' : ''}`
		},
		preferenceOptions () {
			return [
				{
					value: 1,
					text: OPTED_IN
				},
				{
					value: 0,
					text: OPTED_OUT
				},
				{
					value: NO_CHANGE_VALUE,
					text: 'No Pref'
				}
			]
		},
		responseChannelOptions () {
			return this.responseChannels.map(({ responseChannelId, responseChannelName }) => ({
				value: responseChannelId,
				text: responseChannelName
			}))
		},
		sourceCodeOptions () {
			return this.sourceCodes.map(({ sourceCodeId, sourceCodeName }) => ({
				value: sourceCodeId,
				text: sourceCodeName
			}))
		},
		preferenceGroupsMapped () {
			const userBrandValues = csPortalBrandSelector.value.map(brand => {
				return brand.value
			})
			return this.preferenceGroups.map(preferenceGroup => ({
				...preferenceGroup,
				subGroups: preferenceGroup.subGroups.map(subGroup => ({
					...subGroup,
					channels: this.selectedBrand !== 0
						? subGroup.channels.filter(({ brandId }) => brandId === this.selectedBrand || brandId === 0 || this.selectedBrand === NO_BRAND_SELECTED)
						: subGroup.channels.filter(({ brandId }) => userBrandValues.includes(brandId))
				})).filter(({ channels }) => channels.length)
			})).filter(({ subGroups }) => subGroups.length)
		}
	},
	created () {
		if (showResponseChannelForPrefSubmissions.value && this.responseChannelForSubmissionsMandatory) {
			this.selectedResponseChannelId = responseChannels.value.find(({ isDefault }) => isDefault).responseChannelId
		}
		if (showSourceCodeForPrefSubmissions.value && this.sourceCodeForSubmissionsMandatory) {
			this.selectedSourceCodeId = sourceCodes.value.find(({ isDefault }) => isDefault).sourceCodeId
		}
	},
	methods: {
		async createDataSubject (resetValidation) {
			let sourceCodeName = null
			if (showSourceCodeForPrefSubmissions.value) {
				sourceCodeName = sourceCodes.value.find(({ sourceCodeId }) => sourceCodeId === this.selectedSourceCodeId)?.sourceCodeName
			}
			const { dataSubject, preferences } = this
			const preferencesArray = Object.values(preferences)
			for (let index = 0; index < preferencesArray.length; index++) {
				const preference = preferencesArray[index]
				if (this.privacyPolicyMandatory && preference.privacyId == null) {
					this.showSnackbar({ text: 'You must select privacy policy', color: 'red' })
					return
				}
				preference.selectedResponseChannelID = this.selectedResponseChannelId
				preference.sourceCode = sourceCodeName
				const extendedPrefObject = preference.extendedPrefs
				const extendedPrefsArray = []
				for (const key in extendedPrefObject) {
					const extendedPref = {
						keyId: key,
						value: extendedPrefObject[key]
					}
					extendedPrefsArray.push(extendedPref)
				}
				preference.extendedPrefs = extendedPrefsArray
			}
			if (!Object.keys(preferences).length && !this.userHasUniversalBrand) {
				this.showSnackbar({ text: 'You must select at least one preference', color: 'red' })
				return
			}
			if (!Object.keys(dataSubject).length) {
				this.showSnackbar({ text: 'You must enter at least one value in the contact details', color: 'red' })
				return
			}
			dataSubject.submissionSource = 'Create Data Subject'
			await postDataSubject({ dataSubject, preferences })
			resetValidation()
			this.dataSubject = {}
			this.preferences = {}
			this.showSnackbar('This data subject has been created')
		},
		getValue (channel, property, id) {
			const preferenceOverride = this.preferences[channel.channelId]
			if (!preferenceOverride) {
				if (property === 'preferenceValue') return NO_CHANGE_VALUE
				if (property === 'validFrom') return today
				if (property === 'validTo') return ''
				if (property === 'extendedPrefs') {
					const extendedPreference = channel.extendedPrefs.find(option => option.keyId === id)
					return extendedPreference?.defaultValue
				}
				if (property === 'amendDateFrom') return channel.amendDateFrom
				return undefined
			} else {
				if (property === 'extendedPrefs') {
					return preferenceOverride[property][id]
				} else {
					return preferenceOverride[property]
				}
			}
		},
		setChannelOverride (channel, property, value, id, isIndividualChange = true) {
			if (value === NO_CHANGE_VALUE) {
				this.$delete(this.preferences, channel.channelId)
				this.handleGroupSelectionReset(channel, isIndividualChange)
				return
			}
			if (value === 1) {
				this.showError(channel)
			}
			const override = this.preferences[channel.channelId]
			if (!override) {
				this.$set(this.preferences, channel.channelId, {
					channelId: channel.channelId,
					[property]: value,
					validFrom: today,
					validTo: '',
					extendedPrefs: channel.extendedPrefs
						.reduce((acc, { keyId, fieldTypeId, defaultFreeTextValue, defaultSingleSelectValueId }) =>
							({ ...acc, [keyId]: this.getDefaultExtendedPreferenceValue(fieldTypeId, defaultFreeTextValue, defaultSingleSelectValueId) }), {})
				})
			} else {
				const overrideToUpdate = property === 'extendedPrefs' ? override[property] : override
				const propertyToUpdate = property === 'extendedPrefs' ? id : property
				this.$set(overrideToUpdate, propertyToUpdate, value)
			}

			this.handleGroupSelectionReset(channel, isIndividualChange)
		},
		cascadeSelectionToSubGroup (parentGroupId, subGroupId, selectedValue) {
			this.preferenceGroupsMapped.forEach(group => {
				if (group.parentGroupId === parentGroupId) {
					group.subGroups.forEach(subGroup => {
						if (subGroup.subGroupId === subGroupId) {
							subGroup.channels
								.filter(channel => channel.canBeAdded)
								.forEach(channel => {
									this.setChannelOverride(channel, 'preferenceValue', selectedValue !== undefined ? selectedValue : NO_CHANGE_VALUE, null, false)
								})
						}
					})
				}
			})
		},
		handleGroupSelectionReset (channel, isIndividualChange) {
			if (!isIndividualChange) {
				return
			}

			let parentGroupId = null
			let subGroupId = null;

			(this.preferenceGroupsMapped || this.editPreferenceGroups).forEach(group => {
				group.subGroups.forEach(subGroup => {
					if (subGroup.channels.some(ch => ch.channelId === channel.channelId)) {
						parentGroupId = group.parentGroupId
						subGroupId = subGroup.subGroupId
					}
				})
			})

			if (parentGroupId !== null && subGroupId !== null) {
				this.$refs.expansionPanels.resetGroupSelection(parentGroupId, subGroupId)
			}
		},
		getDefaultExtendedPreferenceValue (fieldTypeId, defaultText, defaultSingleSelect) {
			if (fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.FREE_TEXT_FIELD) {
				return defaultText || ''
			} else if (fieldTypeId === EXTENDED_PREFERENCES_FIELD_TYPES.DROPDOWN_FIELD) {
				return defaultSingleSelect || ''
			} else {
				return ''
			}
		},
		isPrivacyPolicyMandatory (channel) {
			return {
				required: this.privacyPolicyMandatory && this.getValue(channel, 'preferenceValue') !== NO_CHANGE_VALUE
			}
		},
		showError (channel) {
			const requiredFields = this.getRequiredFields([channel.channelId])
			if (requiredFields.length > 0) {
				this.requiredStanDakFields = requiredFields.map(x => x.toLowerCase())
				this.showSnackbar({
					text: `${channel.channelName} requires: ${requiredFields.join(', ')}`,
					color: 'error'
				})
			} else {
				this.requiredStanDakFields = []
			}
		},
		invalidChannelSelection (channel) {
			const preferenceValue = this.getValue(channel, 'preferenceValue')
			if (preferenceValue === 0 || preferenceValue === 1) {
				const requiredFields = this.getRequiredFields([channel.channelId])
				return (requiredFields.length > 0)
			} else {
				return false
			}
		},
		getRequiredFields (channels) {
			if (!channels || !channels.length) {
				return []
			}
			const requiredFields = []
			this.channelRequiredStanDakFields
				.filter(({ channelId }) => channels.includes(channelId)).map(x => x.requiredFields).flat().forEach(requiredField => {
					const lowerCaseRequiredField = requiredField.toLowerCase()
					if (!requiredFields.includes(lowerCaseRequiredField)) {
						requiredFields.push(lowerCaseRequiredField)
					}
				})
			const missingFields = requiredFields.filter(field => this.dataSubject[field]?.length === 0 || this.dataSubject[field] == null)
			const missingFieldLabels = this.contactDetailsFields.filter(({ fieldName }) => missingFields.includes(fieldName))
				.map(({ fieldName, fieldLabel }) => {
					const label = fieldLabel.length === 0 ? fieldName : fieldLabel
					return label
				})
			return missingFieldLabels
		}
	}
}
</script>
